* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.content {
  width: 100%;
}

.title {
  margin-bottom: 15px;
}

.areaBotaoDesconectar {
  width: 100%;
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 20px;
}

.btnDesconectar {
  background-color: white;
  border: 1px solid #06a500;
  padding: 10px;
  border-radius: 6px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 950px) {
  .container {
    flex-direction: column;
  }
}
