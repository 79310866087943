.boxLogoHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 80px;
  height: 80px;
  margin: 0px auto;
  border-radius: 100%;
}

.containerLogo {
  padding-top: 20px;
  padding-bottom: 20px;
}

.logoHeader {
  width: 60px;
  height: 60px;
}

.header {
  width: 300px;
  min-width: 300px;
  background-color: #333;
  height: 100vh;
}

.header ul {
  display: flex;
  align-items: center;
  flex-direction: column;
  list-style: none;
}

.header ul li {
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
}

.header a {
  text-decoration: none;
  color: white;
  display: block;
  cursor: pointer;
  padding: 10px;
}

.header li:hover {
  width: 100%;
  background-color: #666;
  transition-duration: 0.2s;
}

@media screen and (max-width: 950px) {
  .header {
    height: auto;
    width: 100%;
  }
}
