.form {
  display: flex;
  flex-direction: column;
}

.boxInput {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.boxInput label {
  margin-bottom: 8px;
  font-weight: 500;
}

.boxInput input {
  border: 1px solid #333;
  border-radius: 6px;
  padding: 10px;
}

.boxInput select {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #333;
  margin-bottom: 10px;
}

.boxInput textarea {
  height: 180px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #333;
  margin-bottom: 10px;
}

.buttonForm {
  width: 160px;
  padding: 10px;
  border-radius: 6px;
  align-self: flex-start;
  border: none;
  background-color: #333;
  color: white;
}

.containerAdicionarConteudo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
}

.containerAreaPreview {
  margin-left: 40px;
}

.previewImage {
  border: 1px solid #333;
  width: 281px;
  height: 340px;
}

.previewImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.previewImageMensagensDoDia {
  width: 281px;
  height: 340px;
  border: 1px solid #333;
}

.previewImageMensagensDoDia img {
  width: 281px;
  height: 340px;
}

.previewImageMensagensDoDia {
  background-image: url("../assets/modelos/mensagens_do_dia.png");
  background-size: cover;
  background-position: center;
}

.previewImage {
  background-image: url("../assets/modelos/padrao.jpg");
  background-size: cover;
  background-position: center;
}

.previewImagePoesiaParaJesus {
  width: 281px;
  height: 340px;
  border: 1px solid #333;
}

.previewImagePoesiaParaJesus img {
  width: 281px;
  height: 340px;
}

.previewImagePoesiaParaJesus {
  background-image: url("../assets/modelos/poesia-para-jesus.png");
  background-size: cover;
  background-position: center;
}

.wrapperModelo {
  width: 250px;
  height: 340px;
  position: absolute;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(213, 213, 213, 0.6); */
}

.boxShare {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.footerShare {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
}

.footerShare img {
  width: 50px;
  height: 100px;
  object-fit: contain;
}

.boxShare span {
  font-size: 8px;
  color: black;
}

.cardModelo1 {
  width: 275px;
  max-width: 275px;
  padding: 15px;
  border-radius: 15px;
  height: auto;
  max-height: 300px;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.6);
  color: white;
}

.containerTextoModelo4 {
  width: 190px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-left: 40px;
  position: absolute;
  top: 204px;
  padding: 15px;
  /* background-color: rgba(148, 84, 84, 0.6); */
}

.containerTextoModelo4 p {
  flex-wrap: wrap;
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

.containerTextosPreviewModelo3 {
  /* Utilize o prefixo -webkit- para garantir compatibilidade com navegadores baseados no motor WebKit, como o Google Chrome e o Safari. */
  -webkit-transform: rotate(45deg);
  /* Padrão transform para navegadores modernos. */
  transform: rotate(-13deg);
  width: 200px;
  display: flex;
  margin-left: 50px;
  margin-top: 20px;
  flex-direction: column;
}

.containerTextosPreviewModelo3 h4 {
  margin-bottom: 8px;
}

.containerTextosPreviewModelo3 p {
  font-size: 12px;
  color: #000;
}

.headerLogoENome {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.containerTextosPreviewModelo2 {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.headerLogoENomeModelo2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
}

.textoModelo2 {
  display: flex;
  font-size: 12px;
  text-align: center;
}

.boxLogo {
  width: 45px;
  height: 45px;
}

.boxLogo img {
  border-radius: 45px;
  width: 100%;
  height: 100%;
}

.nomeEVerificado {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.nomeEVerificado span {
  color: black;
  font-size: 14px;
  font-weight: bold;
}

/* titulo do post */
.cardInfor h4 {
  color: black;
  font-size: 8px;
  margin-bottom: 10px;
}

/* texto do post */
.sizeTextoModelo1 {
  font-size: 10px;
  color: black;
}

@media screen and (max-width: 950px) {
}

@media screen and (min-width: 1028px) {
  .formSmall {
    width: 500px;
  }
}
